import React, { useContext, useEffect, useState } from "react";
import { Accessory } from "../../model";
import styles from "./tool_list.module.css";
import { NavigationContext } from "../../contexts/navigation";
import { RecipeContext } from "../../contexts/recipe";
import { useParams, useHistory } from "react-router";
import AccessoryList from "../../components/accessory_list";
import TButton from "../../components/t_button";
import { BUTTON_TYPES } from "../../components/t_button/t_button";

interface Params {
  recipe_id: string;
}

interface props {
  shouldSelectAll: boolean;
}

const ToolList = ({ shouldSelectAll }: props) => {
  const recipe_id = Number(useParams<Params>().recipe_id);
  const { recipes } = useContext(RecipeContext);
  const recipe = recipes.find((r) => r.id === recipe_id);
  const [accessories, setAccessories] = useState<Accessory[]>([]);

  const [allChecked, setAllChecked] = useState(false);

  const { setHeaderState } = useContext(NavigationContext);

  const history = useHistory();

  useEffect(() => {
    if (recipe) {
      setAccessories(
        recipe.tools.map(
          (t) =>
            ({
              id: t.id,
              position: t.position,
              image: t.tool.image,
              title: t.tool.name,
              video: t.tool.video ? t.tool.video.file : null,
            } as Accessory)
        )
      );
    }
  }, [recipe]);

  useEffect(() => {
    setHeaderState({ title: "Bocka av redskap" });
  }, [setHeaderState]);

  const onAllCheckedChange = (allChecked: boolean) => {
    setAllChecked(allChecked);
  };

  const onContinue = () => {
    if (recipe) history.push(`/wash-hands/${recipe.id}`);
  };

  return (
    <div>
      <AccessoryList
        isTool={true}
        routeKey={"tool-list"}
        recipeId={recipe_id}
        accessories={accessories}
        onAllCheckedChange={onAllCheckedChange}
      />
      {allChecked && recipe ? (
        <div className={styles.buttonContainer}>
          <TButton type={BUTTON_TYPES.action_forward} onClick={onContinue} />
        </div>
      ) : null}

      {shouldSelectAll && (
        <div className={styles.buttonContainer}>
          <TButton type={BUTTON_TYPES.action_forward} onClick={onContinue} />
        </div>
      )}
    </div>
  );
};

export default ToolList;
