import { RecipeIngredient } from "../model";

const singularMeasurementIds = [21, 6];
const singularMeasurementNames = ["cm", "mm", "st", "g", "kg"];

export const getIngredientTitle = ({
  quantity,
  measurement,
  ingredient,
}: RecipeIngredient): string => {
  if (
    singularMeasurementNames.includes(measurement.name.toLowerCase()) ||
    singularMeasurementIds.includes(measurement.id)
  ) {
    return ingredient.name;
  }
  return quantity > 1 ? ingredient.plural_name : ingredient.name;
};
