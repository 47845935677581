import React, { useRef, useState } from "react";
import { Accessory } from "../../model";
import styles from "./accessory_card_two.module.css";
import TouchWraper from "../touch_wraper";
import TButton from "../t_button";
import { BUTTON_TYPES } from "../t_button/t_button";
import check from "../../../assets/new_ui/check@x1.png";
import check_2x from "../../../assets/new_ui/check@x2.png";
import check_3x from "../../../assets/new_ui/check@x3.png";
import questionmark_1x from "../../../assets/new_ui/quest@x1.png";
import questionmark_2x from "../../../assets/new_ui/quest@x2.png";
import questionmark_3x from "../../../assets/new_ui/quest@x3.png";
import { ARIA_ROLES } from "../touch_wraper/roles";

interface Props {
  accessory: Accessory;
  onClick: (accessory: Accessory) => void;
  checked: boolean;
  tool?: boolean;
  hideOverlay?: boolean;
}

const AccessoryCard = ({
  accessory,
  onClick,
  checked,
  tool,
  hideOverlay = false,
}: Props) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleCardClick = () => {
    onClick(accessory);
  };

  const handlePlayVideo = () => {
    setIsVideoPlaying(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleOnVideoEnded = () => {
    setIsVideoPlaying(false);
  };

  const getImageContainerStyle = () => {
    const width = window.innerWidth - 20 < 300 ? window.innerWidth - 20 : 292;
    const height = (width * 9) / 16;

    return {
      backgroundColor: checked ? undefined : accessory.backgroundColor,
      height,
      width,
    };
  };

  return (
    <div className={styles.li}>
      <TouchWraper
        role={ARIA_ROLES.checkbox}
        ariaLabel={accessory.title}
        tabIndex={0}
        onClick={handleCardClick}
      >
        <div className={styles.container}>
          {isVideoPlaying && !hideOverlay && (
            <div className={styles.videoOverlay} />
          )}
          {!isVideoPlaying && (
            <>
              <div className={styles.playButtonContainer}>
                <TButton
                  onClick={handlePlayVideo}
                  type={BUTTON_TYPES.action_play}
                />
              </div>
              <div
                className={[
                  styles.checkContainer,
                  checked ? styles.checked : styles.notChecked,
                ].join(" ")}
              >
                <img
                  src={checked ? check : questionmark_2x}
                  srcSet={
                    checked
                      ? `${check} 1x, ${check_2x} 2x, ${check_3x} 3x`
                      : `${questionmark_1x} 1x, ${questionmark_2x} 2x, ${questionmark_3x} 3x`
                  }
                  alt={checked ? "check" : "question mark"}
                />
              </div>
            </>
          )}
          <div
            style={getImageContainerStyle()}
            className={[
              styles.imageContainer,
              tool && !checked && styles.imageContainerBackground,
            ].join(" ")}
          >
            {accessory.image && (
              <img
                alt={accessory.title}
                style={{ height: "100%" }}
                className={tool && checked ? styles.grayscale : undefined}
                src={accessory.image}
              />
            )}
            <video
              ref={videoRef}
              style={{ visibility: isVideoPlaying ? "visible" : "hidden" }}
              className={styles.video}
              onEnded={handleOnVideoEnded}
              playsInline
              src={accessory.video}
              preload="metadata"
              controls={false}
              poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            />
          </div>
          <div className={styles.bottom}>
            <h2 className={styles.name}>{accessory.title}</h2>
            {!!accessory.subtitle && (
              <h3 className={styles.subtitle}>{accessory.subtitle}</h3>
            )}
          </div>
        </div>
      </TouchWraper>
    </div>
  );
};

export default AccessoryCard;
