import React, { useContext, useEffect, useState } from "react";
import { Accessory } from "../../model";
import styles from "./ingredient_list.module.css";
import { NavigationContext } from "../../contexts/navigation";
import { RecipeContext } from "../../contexts/recipe";
import "./animation.css";
import { useParams, useHistory } from "react-router";
import AccessoryList from "../../components/accessory_list";
import TButton from "../../components/t_button";
import { BUTTON_TYPES } from "../../components/t_button/t_button";
import { ShoppingListContext } from "../../contexts/shopping_list";
import { quantityString } from "../../util/quantity_handler";
import { getIngredientTitle } from "../../util/ingredientHelper";

interface Params {
  recipe_id: string;
}

interface props {
  shouldSelectAll: boolean;
}

const IngredientList = ({ shouldSelectAll }: props) => {
  const recipe_id = Number(useParams<Params>().recipe_id);
  const { recipes } = useContext(RecipeContext);
  const recipe = recipes.find((r) => r.id === recipe_id);
  const [accessories, setAccessories] = useState<Accessory[]>([]);

  const [allChecked, setAllChecked] = useState(false);
  const [checked, setChecked] = useState<number[]>([]);

  const { setHeaderState } = useContext(NavigationContext);

  const history = useHistory();

  const { setUnchecked } = useContext(ShoppingListContext);

  useEffect(() => {
    if (recipe) {
      setAccessories(
        recipe.ingredients.map(
          (i) =>
            ({
              id: i.id,
              position: i.position,
              image: i.ingredient.image,
              title: getIngredientTitle(i),
              subtitle: `${quantityString(i.quantity)} ${
                i.quantity > 1
                  ? i.measurement.full_name_plural
                  : i.measurement.full_name
              }`,
              video: i.video ? i.video.file : null,
              backgroundColor: i.ingredient.category.color,
            } as Accessory)
        )
      );
    }
  }, [recipe]);

  useEffect(() => {
    setHeaderState({ title: "Bocka av ingredienser" });
  }, [setHeaderState]);

  const onAllCheckedChange = (allChecked: boolean) => {
    setAllChecked(allChecked);
  };

  const onCheckedChange = (checked: number[]) => {
    setChecked(checked);
  };

  const onShopClicked = () => {
    const unchecked = accessories
      .map((a) => a.id)
      .filter((id) => !checked.includes(id));
    setUnchecked(unchecked);
    history.push(`/shopping-list/${recipe!.id}`);
  };

  const onContinue = () => {
    if (recipe) history.push(`/tool-list/${recipe.id}`);
  };

  return (
    <>
      <AccessoryList
        routeKey={"ingredient-list"}
        recipeId={recipe_id}
        accessories={accessories}
        onAllCheckedChange={onAllCheckedChange}
        onCheckedChange={onCheckedChange}
      />

      {recipe && (
        <div className={styles.shopButtonContainer}>
          <TButton type={BUTTON_TYPES.action_shop} onClick={onShopClicked} />
        </div>
      )}
      {(shouldSelectAll || (allChecked && recipe)) && (
        <div className={styles.actionButtonContainer}>
          <TButton type={BUTTON_TYPES.action_forward} onClick={onContinue} />
        </div>
      )}
    </>
  );
};

export default IngredientList;
