import React, { useContext, useEffect, useState } from "react";
import { NavigationContext } from "../../contexts/navigation";
import { useParams } from "react-router";
import { RecipeContext } from "../../contexts/recipe";
import { ShoppingListContext } from "../../contexts/shopping_list";
import { RecipeIngredient } from "../../model";
import ShoppingIngredientCard from "../../components/shopping_ingredient_card";
import styles from "./shopping_list.module.css";
import TButton from "../../components/t_button";
import { BUTTON_TYPES } from "../../components/t_button/t_button";

interface Params {
  recipe_id: string;
}

const ShoppingList = () => {
  const recipe_id = Number(useParams<Params>().recipe_id);
  const { recipes } = useContext(RecipeContext);
  const recipe = recipes.find((r) => r.id === recipe_id);

  const [ingredients, setIngredients] = useState<RecipeIngredient[]>([]);

  const { unchecked } = useContext(ShoppingListContext);

  const { setHeaderState } = useContext(NavigationContext);

  useEffect(() => {
    if (recipe && unchecked) {
      setIngredients(
        recipe.ingredients.filter((i) => unchecked.includes(i.id))
      );
    }
  }, [unchecked, recipe]);

  useEffect(() => {
    setHeaderState({ title: "Inköpslista" });
  }, [setHeaderState]);

  const onShare = (ingredients: RecipeIngredient[]) => {
    const title = `Handlingslista - ${recipe!.name}`;
    const ings = ingredients
      .filter((i) => i.ingredient.name.toLocaleLowerCase() !== "vatten")
      .map(
        (i) =>
          `${i.ingredient.name} - ${i.quantity_shop} ${i.measurement_shop.name}`
      )
      .join("\n");
    const msg = `${title}\n\n${ings}`;
    if ("share" in navigator) {
      (navigator as any)
        .share({
          title: title,
          text: msg,
        })
        .then(() => console.log("Successful share"))
        .catch((err: string) => console.log("Error sharing:", err));
    } else if ("plugins" in window) {
      (window as any).plugins.socialsharing.shareWithOptions(
        { message: msg },
        (res: string) => console.log("RES " + res),
        (err: string) => console.log("ERR " + err)
      );
    } else {
      const body = msg.replace(/\n/g, escape("\r\n"));
      window.location.href = `mailto:?body=${body}&subject=${title}`;
    }
  };

  const onCardClicked = (id: number) => {
    //setIngredients(ingredients.filter(i => i.id != id))
  };

  return (
    <div className={styles.container}>
      <div className={styles.list_container}>
        <ul className={styles.list}>
          {ingredients.map((ingredient) => (
            <ShoppingIngredientCard
              key={ingredient.id}
              ingredient={ingredient}
              onCardClick={onCardClicked}
            />
          ))}
        </ul>
      </div>
      <div className={styles.shareButtonContainer}>
        <TButton
          type={BUTTON_TYPES.action_share}
          onClick={() => onShare(ingredients)}
        />
      </div>
    </div>
  );
};

export default ShoppingList;
