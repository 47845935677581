import React, { Dispatch, SetStateAction } from "react";

type checked = {
    all: boolean,
    ids: number[],
    num: number
}

export interface AccessoryState {
    [key:string]: {
        recipes: {
            [key:number]: checked
        }
    }
}
export interface IAccessoryContext {
    accessory_state: AccessoryState,
    setAccessoryState: Dispatch<SetStateAction<AccessoryState>>
}
export const AccessoryContext = React.createContext<IAccessoryContext>({
    setAccessoryState: (state) => {},
    accessory_state: {}
})