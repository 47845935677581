import SoupImage from "../assets/recipe_categories/soppa_1280x720.jpg";
import VegoImage from "../assets/recipe_categories/vego_1280x720.jpg";
import SalladImage from "../assets/recipe_categories/sallad_1280x720.jpg";
import MeatImage from "../assets/recipe_categories/kott_1280x720.jpg";
import FishImage from "../assets/recipe_categories/fisk_1280x720.jpg";
import FridayImage from "../assets/recipe_categories/fredagsmys_1280x720.jpg";
import PastaImage from "../assets/recipe_categories/pasta_1280x720.jpg";
import ChickenImage from "../assets/recipe_categories/kyckling_1280x720.jpg";
import SausageImage from "../assets/recipe_categories/korv_1280x720.jpg";

export const getRecipeCategoryImage = (categoryId: number) => {
  switch (categoryId) {
    case 3:
      return SoupImage;
    case 4:
      return VegoImage;
    case 5:
      return SalladImage;
    case 6:
      return MeatImage;
    case 7:
      return FishImage;
    case 8:
      return FridayImage;
    case 9:
      return PastaImage;
    case 10:
      return ChickenImage;
    case 11:
      return SausageImage;
    default:
      return undefined;
  }
};

export const getRecipeCategoryVideo = (categoryId: number) => {
  switch (categoryId) {
    case 3:
      return "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/receptkategorier%2Fsoppa.mp4?alt=media&token=a280ee10-e649-4da0-8b98-dd442946bce6";
    case 4:
      return "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/receptkategorier%2Fvego.mp4?alt=media&token=8147ef49-e5e5-434d-946e-70ca8427ded9";
    case 5:
      return "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/receptkategorier%2Fsallad.mp4?alt=media&token=9fdf83cd-f7c9-42a4-8862-276934637ddd";
    case 6:
      return "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/receptkategorier%2Fkott.mp4?alt=media&token=2b2f8cca-af1f-47f9-b906-274d85b4cb3a";
    case 7:
      return "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/receptkategorier%2Ffisk.mp4?alt=media&token=ab2511ec-9ca3-433d-8b8d-9d845898ece1";
    case 8:
      return "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/receptkategorier%2Ffredagsmys.mp4?alt=media&token=25bbea8f-8115-4a52-b87c-1d880ab58b90";
    case 9:
      return "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/receptkategorier%2Fpasta.mp4?alt=media&token=aa19bbfa-f2df-4a38-8749-b576ab6d9009";
    case 10:
      return "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/receptkategorier%2Fkyckling.mp4?alt=media&token=4d220c40-eeef-450c-8dbd-a07c321ec7c4";
    case 11:
      return "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/receptkategorier%2Fkorv.mp4?alt=media&token=7d9a27e6-0472-4ec8-947f-2886747763c2";
    default:
      return undefined;
  }
};
