import React, { useState } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import RecipeList from "../screens/recipe_list";
import Header from "../components/header";
import { NavigationContext, HeaderState } from "../contexts/navigation";
import IngredientList from "../screens/ingredient_list";
import RecipeInstruction from "../screens/recipe_instruction";
import ToolList from "../screens/tool_list";
import WashHands from "../screens/wash_hands";
import ShoppingList from "../screens/shopping_list";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./animations.css";
import styles from "./navigation.module.css";
import { Location } from "history";
import Drawer from "../components/drawer";
import TBackground from "../components/t_background";
import HackIcon from "../components/hack_icon";
import CategoryList from "../screens/category_list";
import RecipeDetails from "../screens/recipe_details";
import { PrivacyPolicyPage } from "../screens/privacy_policy";
import Home from "../screens/home";

const Navigation = () => {
  const [header_state, setHeaderState] = useState<HeaderState>({
    title: "DEFAULT",
    hasBackButton: true,
    showDrawer: false,
  });

  const [didPopState, setDidPopState] = useState(false);
  const [index, setIndex] = useState(0);
  const [stack, setStack] = useState<string[]>([]);
  const [selectAllAcc, setSelectAllAcc] = useState(false);

  window.onpopstate = (ev: PopStateEvent) => {
    if (ev.currentTarget !== null && "location" in ev.currentTarget) {
      setDidPopState(didPop(ev.currentTarget["location"]["hash"]));
    }
  };

  const didPop = (path: string) => {
    if (stack.find((p) => p === path) !== undefined) {
      const a = Array.from(stack);
      a.pop();
      setStack(a);
      return true;
    } else {
      const a = Array.from(stack);
      a.push(path);
      setStack(a);
      return false;
    }
  };

  const onEnter = () => {
    if (didPopState) setIndex(index >= 0 ? index - 1 : 0);
    else setIndex(index + 1);
    setDidPopState(false);
  };

  const getClassNames = (l: Location) => {
    if (l.pathname.includes("ingredient-list") && didPopState)
      return "overviewback";
    if (l.pathname.includes("recipe-overview")) return "overview";
    if (
      l.pathname.includes("recipe-list") ||
      l.pathname.includes("category-list")
    )
      return "";
    if (l.pathname.includes("shopping-list")) return "nav-up";
    return !didPopState ? "nav-right" : "nav-left";
  };

  const isRecipeOverViewFromPop = (l: Location) => {
    return true;
  };

  const closeDrawer = () => {
    setHeaderState({ ...header_state, showDrawer: false });
  };

  const resetStack = () => {
    setStack([]);
  };

  const dumbLinks = {
    categories: {
      title: "Kategorier",
      path: "/recipe-list",
    },
    recipes: {
      title: "Recept",
      path: "/recipe-list",
    },
    movies: {
      title: "Filmer",
      path: "/recipe-list",
    },
    settings: {
      title: "Inställningar",
      path: "/recipe-list",
    },
  };

  const onHackIconLong = () => {
    setSelectAllAcc(true);
    setTimeout(() => {
      setSelectAllAcc(false);
    }, 4000);
  };

  return (
    <NavigationContext.Provider value={{ header_state, setHeaderState }}>
      <Router>
        <Route
          render={({ location, history }) => (
            <div>
              <Drawer
                links={dumbLinks}
                closeDrawer={closeDrawer}
                open={header_state.showDrawer || false}
              />
              <TransitionGroup>
                <CSSTransition
                  key={location.pathname}
                  onEntered={() => onEnter()}
                  timeout={250}
                  classNames={getClassNames(location)}
                >
                  <TBackground className={styles.container}>
                    <Switch location={location}>
                      <Route path="/" exact>
                        <Home />
                      </Route>
                      <Route path="/category-list">
                        <Header
                          onGoBack={() => history.replace("/")}
                          infoData={{
                            text: "Tryck på pilen för att se teckenspråk.\nTryck på matbild för att välja recept.",
                            videoUrl:
                              "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/inforutor%2Finfo_vid1.mp4?alt=media&token=e930c451-352e-470d-bd49-7633a328ad53",
                          }}
                        />
                        <CategoryList />
                      </Route>
                      <Route path="/recipe-list/:category_id">
                        <Header
                          onGoBack={() => history.replace("/category-list")}
                          infoData={{
                            text: "Tryck på pilen för att se teckenspråk.\nTryck på matbild för att välja recept.",
                            videoUrl:
                              "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/inforutor%2Finfo_vid1.mp4?alt=media&token=e930c451-352e-470d-bd49-7633a328ad53",
                          }}
                        />
                        <RecipeList />
                      </Route>
                      <Route path="/recipe-overview/:recipe_id">
                        <RecipeDetails
                          didPop={isRecipeOverViewFromPop(location)}
                        />
                      </Route>
                      <Route path="/ingredient-list/:recipe_id">
                        <Header
                          infoData={{
                            text: "Tryck på pilen för att se teckenspråk.\nTryck på ingrediens som finns hemma.\n Tryck på varukorgen för inköpslista.\nBocka av alla ingredienser för att komma vidare.",
                            videoUrl:
                              "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/inforutor%2Finfo_vid2.mp4?alt=media&token=397873ab-a6a8-4179-a1fb-c57caba4110b",
                          }}
                          right={<HackIcon onTapSuccess={onHackIconLong} />}
                        />
                        <IngredientList shouldSelectAll={selectAllAcc} />
                      </Route>
                      <Route path="/shopping-list/:recipe_id">
                        <Header
                          infoData={{
                            text: "Tryck på pilen för att se teckenspråk.\nTryck på ingrediens som handlas.",
                            videoUrl:
                              "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/inforutor%2Finfo_vid3.mp4?alt=media&token=f8d081a4-0802-4e1f-a11c-cacc86caece2",
                          }}
                        />
                        <ShoppingList />
                      </Route>
                      <Route path="/tool-list/:recipe_id">
                        <Header
                          infoData={{
                            text: "Tryck på pilen för att se teckenspråk.\nTryck och bocka av redskap som behövs.\nBocka av alla redskap för att komma vidare.",
                            videoUrl:
                              "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/inforutor%2Finfo_vid4.mp4?alt=media&token=d1b6b017-df88-4ca7-bb84-2dc6106a70d5",
                          }}
                          right={<HackIcon onTapSuccess={onHackIconLong} />}
                        />
                        <ToolList shouldSelectAll={selectAllAcc} />
                      </Route>
                      <Route path="/wash-hands/:recipe_id">
                        <Header />
                        <WashHands />
                      </Route>
                      <Route path="/recipe-instruction/:recipe_id">
                        <RecipeInstruction onCancel={resetStack} />
                      </Route>
                      <Route path="/integritetspolicy/:app_name">
                        <PrivacyPolicyPage />
                      </Route>
                    </Switch>
                  </TBackground>
                </CSSTransition>
              </TransitionGroup>
            </div>
          )}
        ></Route>
      </Router>
    </NavigationContext.Provider>
  );
};

export default Navigation;
