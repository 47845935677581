import React, { useState, useEffect } from "react";
import styles from "./touch_wraper.module.css";
import { ARIA_ROLES } from "./roles";

interface ITouchWraper {
  onClick: () => void;
  role?: ARIA_ROLES;
  ariaLabel?: string;
  tabIndex?: number;
}

export interface TouchWraperEvent {
  stopPropagation: () => void;
  preventDefault: () => void;
}

let touchWraperIdCounter = 0;

const TouchWraper: React.SFC<ITouchWraper> = ({
  onClick,
  children,
  ariaLabel,
  role,
  tabIndex,
}) => {
  touchWraperIdCounter++;
  const [animate, setAnimate] = useState(false);
  const [didAnimate, setDidAnimate] = useState(false);
  const [childMargin, setChildMargin] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const id = "touch-wraper-container-" + touchWraperIdCounter;
  const [size, setSize] = useState({ height: 0, width: 0 });
  const [midSize, setMidSize] = useState({
    height: 0,
    width: 0,
    borderRadius: "",
  });
  const [didTouch, setDidTouch] = useState(false);
  const [didMouse, setDidMouse] = useState(false);
  const [ariaPressed] = useState(false);
  const [cancelTouch, setCancelTouch] = useState(false);

  let stopAnimation = false;

  const setHeight = () => {
    const container = document.getElementById(id);
    let height = 0;
    let width = 0;
    if (container) {
      height = container.offsetHeight;
      width = container.offsetWidth;
      setSize({
        height: height,
        width: width,
      });
    }
    return {
      height: height,
      width: width,
    };
  };

  useEffect(() => {
    window.addEventListener("resize", reset);
    return () => window.removeEventListener("resize", reset);
  }, []);

  const reset = () => {
    setDidAnimate(false);
    setDidMouse(false);
    setDidTouch(false);
    setPosition({ x: 0, y: 0 });
    setSize({ height: 0, width: 0 });
  };

  const onStart = () => {
    const sizes = setHeight();
    const cont = document.getElementById(id)!;
    const child = cont.children[0] as HTMLDivElement;
    if (child) {
      const margin = window.getComputedStyle(child).margin;
      const borderRadius = window.getComputedStyle(child).borderRadius;
      let marginNumber = 0;
      if (margin !== null) {
        const nmargin = margin.match(/\d+/);
        if (nmargin) {
          marginNumber = parseInt(nmargin[0]);
        }
      }
      setChildMargin(marginNumber);
      setMidSize({
        height: sizes.height - marginNumber * 2,
        width: sizes.width - marginNumber * 2,
        borderRadius: borderRadius || "",
      });
    }
    return sizes;
  };

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!didMouse) {
      e.stopPropagation();
      setCancelTouch(false);
      setDidAnimate(false);
      setDidTouch(false);
      setDidTouch(true);
      const sizes = onStart();
      setPosition({
        x:
          e.touches[0].pageX -
          e.currentTarget.getBoundingClientRect().left -
          sizes.width / 2,
        y:
          e.touches[0].pageY -
          e.currentTarget.getBoundingClientRect().top -
          sizes.height / 2,
      });
      startAnim();
    }
  };

  const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!didMouse) {
      if (!stopAnimation) {
        e.stopPropagation();
        e.preventDefault();
      }

      endAnim(e);
    }
  };

  const onTouchCancel = () => {
    stopAnimation = true;
    setCancelTouch(true);
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.button === 0) {
      if (!didTouch) {
        e.stopPropagation();
        setDidAnimate(false);
        setDidMouse(false);
        setDidMouse(true);
        const sizes = onStart();
        setPosition({
          x:
            e.pageX -
            e.currentTarget.getBoundingClientRect().left -
            sizes.width / 2,
          y:
            e.pageY -
            e.currentTarget.getBoundingClientRect().top -
            sizes.height / 2,
        });
        startAnim();
      }
    }
  };

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.button === 0) {
      if (!didTouch) {
        if (!stopAnimation) {
          e.stopPropagation();
          e.preventDefault();
        }
        endAnim();
      }
    }
  };

  const startAnim = () => {
    setTimeout(() => {
      checkShouldAnimate();
    }, 150);
  };

  const checkShouldAnimate = () => {
    if (!stopAnimation) {
      setAnimate(true);
      // onWraperClicked()
    }
  };

  const endAnim = (e?: React.TouchEvent<HTMLDivElement>) => {
    setTimeout(() => {
      setDidAnimate(true);
      if (!stopAnimation) onWraperClicked();
      setTimeout(() => {
        setAnimate(false);
      }, 200);
    }, 50);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.which === 13 || e.which === 32) {
      onWraperClicked();
    }
  };

  const onWraperClicked = () => {
    onClick();
  };

  return (
    <div
      className={styles.wraper}
      tabIndex={tabIndex || 0}
      onKeyDown={onKeyDown}
    >
      <div
        id={id}
        tabIndex={-1}
        role={role || "button"}
        aria-label={ariaLabel || "Knapp"}
        aria-pressed={ariaPressed}
        style={{
          height: size.height > 0 ? size.height : undefined,
          width: size.width > 0 ? size.width : undefined,
        }}
        className={styles.container}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchCancel}
      >
        {children}
        <div
          style={{
            height: midSize.height,
            width: midSize.width,
            top: childMargin,
            left: childMargin,
            borderRadius: midSize.borderRadius,
          }}
          className={styles.midContainer}
        >
          {animate && !cancelTouch && (
            <div
              style={{
                top: position.y,
                left: position.x,
                height: size.height,
                width: size.width,
              }}
              className={[
                styles.animDiv,
                didAnimate && styles.animDivHide,
              ].join(" ")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TouchWraper;
