import React from "react";
import styles from "./t_background.module.css";

interface props {
  className?: string;
  disable?: boolean;
}

const TBackground: React.FunctionComponent<props> = (props) => {
  const getBg = () => {
    if (props.disable) return "";
    if (!window.cordova) return styles.web;
    return window.isTablet ? styles.tablet : styles.phone;
  };
  return (
    <div className={[styles.background, getBg(), props.className].join(" ")}>
      {props.children}
    </div>
  );
};

export default TBackground;
