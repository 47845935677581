import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const startApp = () =>
  ReactDOM.render(<App />, document.getElementById("root"));

const initWindow = () => {
  const agent = navigator.userAgent;
  const isWebkit = agent.indexOf("AppleWebKit") > 0;
  const isIPad = agent.indexOf("iPad") > 0;
  const isIOS = agent.indexOf("iPhone") > 0 || agent.indexOf("iPod") > 0;
  const isAndroid = agent.indexOf("Android") > 0;
  const isNewBlackBerry =
    agent.indexOf("AppleWebKit") > 0 && agent.indexOf("BlackBerry") > 0;
  const isWebOS = agent.indexOf("webOS") > 0;
  const isWindowsMobile = agent.indexOf("IEMobile") > 0;
  const isSmallScreen =
    window.screen.width < 767 || (isAndroid && window.screen.width < 1000);
  const isUnknownMobile = isWebkit && isSmallScreen;
  const isMobile =
    isIOS ||
    isAndroid ||
    isNewBlackBerry ||
    isWebOS ||
    isWindowsMobile ||
    isUnknownMobile;
  window.isTablet = isIPad || (isMobile && !isSmallScreen);
  window.isLargeScreen = !isSmallScreen;
};

initWindow();

if (!window.cordova) {
  startApp();
} else {
  document.addEventListener("deviceready", startApp, false);
}

const deviceReady = () => {
  //https://stackoverflow.com/a/17230890/611076

  if (window.isTablet) {
    window.screen.orientation.lock("landscape");
  } else {
    window.screen.orientation.lock("portrait");
  }
  /* Doesn't seem to make any difference. Needs to be tested in prod.
    document.addEventListener("backbutton", e => {
        e.preventDefault()
        window.history.back()
    })
    */
};
document.addEventListener("deviceready", deviceReady, false);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
