import React from "react";
import styles from "./player_controls.module.css";
import close from "../../../../assets/new_ui/close.svg";
import TButton from "../../t_button";
import { BUTTON_TYPES } from "../../t_button/t_button";
import Header from "../../header";
import CloseButton from "../../close_button";
import PlayButton from "../../play_button";
import { PlayButtonTypes } from "../../play_button/play_button";

interface props {
  onCloseDone: () => void;
  onPlayClick: () => void;
  onReplayClick: () => void;
  onSignReplayClick: () => void;
  isPlaying: boolean;
  stepNumber: number;
  totalSteps: number;
  onNextClick: () => void;
  onPreviousClick: () => void;
  onCancelClick: () => void;
  onDoneClick: () => void;
  landscape: boolean;
  showControls: boolean;
  didEnd: boolean;
  name: string;
}

const PlayerControls = ({
  onCloseDone,
  onPlayClick,
  onReplayClick,
  isPlaying,
  stepNumber,
  totalSteps,
  onNextClick,
  onPreviousClick,
  onCancelClick,
  landscape,
  showControls,
  didEnd,
  name,
  onSignReplayClick,
  onDoneClick,
}: props) => {
  const id = "player_controls_container";
  const closeId = "player_controls_close_button";

  const onClose = (e?: React.MouseEvent) => {
    if (e) e.stopPropagation();
    const container = document.getElementById(id);
    if (container) container.classList.toggle(styles.hide);
    setTimeout(() => onCloseDone(), 500);
  };

  const onPlay = () => {
    onPlayClick();
    onClose();
  };

  const onReplay = () => {
    onReplayClick();
    onClose();
  };

  const onSignReplay = () => {
    onSignReplayClick();
    onClose();
  };

  const onPause = () => {
    onPlayClick();
  };

  const onNext = () => {
    if (stepNumber === totalSteps) {
      onDoneClick();
      return;
    }
    onNextClick();
  };

  const onPrevious = () => {
    onPreviousClick();
  };

  const onCancel = () => {
    onCancelClick();
  };

  const getStatusBar = () => {
    const generalStyle = {
      marginLeft: (window.innerWidth * 0.75) / totalSteps / 4,
      marginRight: (window.innerWidth * 0.75) / totalSteps / 4,
    };

    return Array.apply(null, Array(totalSteps)).map((_, index) => (
      <div
        key={index}
        style={generalStyle}
        className={[
          styles.step,
          index + 1 > stepNumber ? styles.remaining_step : {},
          index === stepNumber - 1 ? styles.current_step : styles.done_step,
        ].join(" ")}
      />
    ));
  };

  const NavigationContainer = () => (
    <div
      className={[
        styles.control_containers,
        styles.three,
        stepNumber === 1 && styles.flex_end,
        landscape ? {} : styles.three_portrait,
      ].join(" ")}
    >
      {stepNumber !== 1 && (
        <TButton type={BUTTON_TYPES.action_back} onClick={onPrevious} />
      )}
      <TButton type={BUTTON_TYPES.action_forward} onClick={onNext} />
    </div>
  );

  const ActionButtons = () => {
    if (didEnd) {
      return (
        <>
          {stepNumber > 1 && stepNumber !== totalSteps && (
            <>
              <PlayButton
                type={PlayButtonTypes.play}
                className={styles.replayButton}
                onClick={onReplay}
              />
              <div className={styles.divider} />
            </>
          )}
          <PlayButton type={PlayButtonTypes.sign} onClick={onSignReplay} />
        </>
      );
    }
    return (
      <PlayButton
        type={isPlaying ? PlayButtonTypes.pause : PlayButtonTypes.play}
        onClick={isPlaying ? onPause : onPlay}
      />
    );
  };

  return landscape ? (
    <div onClick={onClose} id={id} className={styles.container}>
      <div className={[styles.control_containers, styles.one].join(" ")}>
        <img
          id={closeId}
          onClick={onCancel}
          className={styles.close_button}
          src={close}
          alt="avsluta recept"
        />
        <div className={styles.status_bar}>{getStatusBar()}</div>
        <div className={styles.right} />
      </div>
      <div className={[styles.control_containers, styles.two].join(" ")}>
        <ActionButtons />
      </div>
      <NavigationContainer />
    </div>
  ) : (
    <div className={styles.container_porttrait}>
      <Header left={<CloseButton onClick={onCancel} />} title={name} />
      {showControls && (
        <div
          onClick={onClose}
          id={id}
          className={styles.two_portrait}
          style={{ height: (window.innerWidth * 9) / 16 }}
        >
          <div
            className={[styles.status_bar, styles.status_bar_full_width].join(
              " "
            )}
          >
            {getStatusBar()}
          </div>
          <div className={styles.playbuttons_portrait}>
            <ActionButtons />
          </div>
        </div>
      )}
      {(showControls || (!isPlaying && didEnd)) && <NavigationContainer />}
    </div>
  );
};

export default PlayerControls;
