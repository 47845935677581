import React, { useState, useEffect } from "react";
import { Recipe, RecipesByCategory } from "./model";
import Navigation from "./navigation";
import { RecipeContext } from "./contexts/recipe";
import { AccessoryContext, AccessoryState } from "./contexts/accessory";
import { ShoppingListContext } from "./contexts/shopping_list";
import Loader from "./components/loader";
import { fetchRecipesByCategory } from "./model/data_handler";

const AppContainer = () => {
  const [state, setState] = useState({
    recipes: [] as Recipe[],
    recipesByCategory: [] as RecipesByCategory[],
  });
  const [accessory_state, setAccessoryState] = useState<AccessoryState>({});
  const [unchecked, setUnchecked] = useState<Array<number>>(() => []);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRecipes = async () => {
      const recipesByCategory = await fetchRecipesByCategory();

      if (recipesByCategory) {
        setState((prevState) => ({
          ...prevState,
          recipesByCategory,
        }));
      }
      setIsLoading(false);
    };
    if (window.location.hash.includes("integritetspolicy")) {
      setIsLoading(false);
      return;
    }
    fetchRecipes();
  }, []);

  const setRecipes = (recipes: Recipe[]) => {
    setState({ ...state, recipes });
  };

  return (
    <RecipeContext.Provider value={{ ...state, setRecipes }}>
      <AccessoryContext.Provider value={{ accessory_state, setAccessoryState }}>
        <ShoppingListContext.Provider value={{ unchecked, setUnchecked }}>
          {isLoading ? <Loader /> : <Navigation />}
        </ShoppingListContext.Provider>
      </AccessoryContext.Provider>
    </RecipeContext.Provider>
  );
};

export default AppContainer;
