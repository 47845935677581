import React, { useContext, useState } from "react";
import { useParams, Redirect } from "react-router";
// import styles from './recipe_instruction.module.css'
import RecipeInstructionHandler from "../../components/recipe_instruction_handler";
import { RecipeContext } from "../../contexts/recipe";

interface Params {
  recipe_id: string;
}
interface Props {
  onCancel: () => void;
}
const RecipeInstruction = ({ onCancel }: Props) => {
  const recipe_id = Number(useParams<Params>().recipe_id);
  const { recipes } = useContext(RecipeContext);
  const recipe = recipes.find((r) => r.id === recipe_id);

  const [shouldRedirect, setShouldRedirect] = useState(false);

  const cancelRecipe = () => {
    setShouldRedirect(true);
    onCancel();
  };

  if (shouldRedirect) return <Redirect to="/category-list" />;
  return (
    <div>
      {recipe !== undefined ? (
        <RecipeInstructionHandler
          onCloseRecipe={cancelRecipe}
          recipe={recipe}
        />
      ) : null}
    </div>
  );
};

export default RecipeInstruction;
