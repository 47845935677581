import React, { useContext, useState, useEffect } from "react";
import styles from "./wash_hands.module.css";
import { RecipeContext } from "../../contexts/recipe";
import { useParams, useHistory } from "react-router";
import AccessoryList from "../../components/accessory_list";
import ScreenContainer from "../screen_container";
import washImage from "../../../assets/img/wash.png";
import washVideo from "../../../assets/video/wash_hands.mp4";
import { NavigationContext } from "../../contexts/navigation";
import { Accessory } from "../../model";
import TButton from "../../components/t_button";
import { BUTTON_TYPES } from "../../components/t_button/t_button";

interface Params {
  recipe_id: string;
}

const WashHands = () => {
  const recipe_id = Number(useParams<Params>().recipe_id);
  const { recipes } = useContext(RecipeContext);
  const recipe = recipes.find((r) => r.id === recipe_id);

  const { setHeaderState } = useContext(NavigationContext);
  const history = useHistory();

  useEffect(() => {
    setHeaderState({ title: "Tvätta händerna" });
  }, [setHeaderState]);
  const [allChecked, setAllChecked] = useState(false);
  const [accessories, setAccessories] = useState<Accessory[]>([]);

  const onAllCheckedChange = (allChecked: boolean) => {
    setAllChecked(allChecked);
  };

  useEffect(() => {
    setAccessories([
      {
        id: 1,
        title: "Tvätta händerna",
        image: washImage,
        video: washVideo,
        position: 1,
        backgroundColor: "#2086BC",
      },
    ]);
  }, []);

  const onContinue = () => {
    if (recipe) history.push(`/recipe-instruction/${recipe.id}`);
  };

  return (
    <ScreenContainer>
      <div className={styles.wrapperOuter}>
        <div className={styles.wrapperInner}>
          <AccessoryList
            routeKey={"wash-hands"}
            recipeId={recipe_id}
            accessories={accessories}
            onAllCheckedChange={onAllCheckedChange}
            renderEmptyCards={false}
            hideOverlay
          />
        </div>
      </div>
      {allChecked && recipe ? (
        <div className={styles.buttonContainer}>
          <TButton type={BUTTON_TYPES.action_forward} onClick={onContinue} />
        </div>
      ) : null}
    </ScreenContainer>
  );
};

export default WashHands;
