import React, { useContext, useMemo } from "react";
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import RecipeOverview from "../../components/recipe_overview";
import { RecipeContext } from "../../contexts/recipe";

interface Params {
  recipe_id?: string;
}

interface RecipeDetails extends RouteComponentProps {
  didPop?: boolean;
}

const RecipeDetails: React.FC<RecipeDetails> = ({
  history,
  didPop = false,
}) => {
  const { recipesByCategory } = useContext(RecipeContext);

  const { recipe_id } = useParams<Params>();
  const recipeId = recipe_id ? Number(recipe_id) : undefined;

  const recipe = useMemo(() => {
    if (!recipeId) return;

    let recipe;
    for (let i = 0; i < recipesByCategory.length; i++) {
      const foundRecipe = recipesByCategory[i].recipes.find(
        (rec) => rec.id === recipeId
      );
      if (foundRecipe) {
        recipe = foundRecipe;
        break;
      }
    }
    return recipe;
  }, [recipeId, recipesByCategory]);

  const onContinueRecipe = () => {
    if (recipe) history.push(`/ingredient-list/${recipe.id}`);
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <CSSTransition timeout={200} classNames={"overview"} in>
      <RecipeOverview
        onContinue={onContinueRecipe}
        onClose={handleClose}
        doneAnimating={didPop}
        recipe={recipe}
      />
    </CSSTransition>
  );
};

export default withRouter(RecipeDetails);
