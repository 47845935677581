import React, { useState, useRef } from "react";
import styles from "./recipe_card.module.css";
import "./animation.css";
import TouchWraper from "../touch_wraper";
import TButton from "../t_button";
import { BUTTON_TYPES } from "../t_button/t_button";

type CardData = {
  id: number;
  name: string;
  image: string;
  video: string;
};

interface Props {
  onCardClicked: () => void;
  data: CardData;
}

const RecipeCard = ({ onCardClicked, data }: Props) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoPlayer = useRef<HTMLVideoElement>(null);

  const handleCardClicked = () => {
    onCardClicked();
  };

  const playVideo = () => {
    setIsVideoPlaying(true);
    if (videoPlayer.current) {
      videoPlayer.current.play();
    }
  };

  const handleOnVideoEnded = () => {
    setIsVideoPlaying(false);
  };

  return (
    <div className={styles.li}>
      <TouchWraper onClick={handleCardClicked}>
        <div className={styles.container}>
          {isVideoPlaying ? (
            <div className={styles.videoOverlay} />
          ) : (
            <div className={styles.playButtonContainer}>
              <TButton onClick={playVideo} type={BUTTON_TYPES.action_play} />
            </div>
          )}
          <div className={styles.imageContainer}>
            <img alt="" className={styles.img} src={data.image} />
            <video
              ref={videoPlayer}
              style={{ visibility: isVideoPlaying ? "visible" : "hidden" }}
              className={styles.video}
              onEnded={handleOnVideoEnded}
              playsInline
              src={data.video}
              preload="metadata"
              controls={false}
              poster={data.image}
            />
          </div>
          <div className={styles.bottom}>
            <h2 className={styles.name}>{data.name}</h2>
          </div>
        </div>
      </TouchWraper>
    </div>
  );
};

export default RecipeCard;
