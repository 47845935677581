import React from 'react'
import styles from './screen_container.module.css'

interface props {
    className?: string
}

const ScreenContainer : React.FunctionComponent<props> = props => {
    const classNames = props.className ? [props.className, styles.container].join(' ') : styles.container
    return (
        <div className={classNames}>
            {props.children}
        </div>
    )
}

export default ScreenContainer
