import React from 'react'
import styles from './close_button.module.css'
import closeIcon from '../../../assets/new_ui/close.svg'
import TouchWraper from '../touch_wraper'

const CloseButton = ({onClick}: {onClick: () => void}) => {
    return (
        <TouchWraper ariaLabel='Stäng' onClick={onClick} >
            <img className={styles.button} src={closeIcon} alt="Stäng" />
        </TouchWraper>
    )
}

export default CloseButton
