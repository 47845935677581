import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styles from "./home.module.css";
import home1Img from "../../../assets/new_ui/home_page_1.png";
import home2Img from "../../../assets/new_ui/home_page_2.png";
import logo1 from "../../../assets/new_ui/logo1.png";
import logo2 from "../../../assets/new_ui/logo2.png";
import CloseButton from "../../components/close_button";

const HANDBOOK_URL =
  "https://hushallningssallskapet.se/matglad/wp-content/uploads/sites/24/2023/09/Slutlig_MatGlad-helt-enkelt-pa-TSP-Elevinstruktioner-2023-09-11.pdf";

const HomePage = ({ history }: RouteComponentProps) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const isSmallScreen = !window.isLargeScreen;

  return (
    <div
      className={[
        styles.container,
        isSmallScreen ? styles.containerSmall : undefined,
      ].join(" ")}
    >
      {isVideoPlaying && (
        <>
          <iframe
            title="yt info video"
            className={[
              styles.video,
              isVideoPlaying ? styles.videoShow : undefined,
            ].join(" ")}
            src="https://www.youtube.com/embed/-cLTGKiwqTU?si=Z8XjsDh4zjWKXVZl"
          />
          <div className={styles.closeVideoBtn}>
            <CloseButton onClick={() => setIsVideoPlaying(false)} />
          </div>
        </>
      )}
      <div
        className={[
          styles.containerActionBtns,
          isSmallScreen ? styles.containerActionBtnsSmall : undefined,
        ].join(" ")}
      >
        <div
          onClick={() => setIsVideoPlaying(true)}
          className={styles.containerimgBtn}
        >
          <img
            className={[
              styles.imageButton,
              isSmallScreen ? styles.imageButtonSmall : undefined,
              isSmallScreen ? styles.imageSmall : undefined,
            ].join(" ")}
            src={home2Img}
            alt="play video"
          />
          <h4 className={styles.btnTitle}>Se demofillm</h4>
        </div>
        <div className={styles.spacer} />
        <div onClick={() => history.push("/category-list")}>
          <img
            className={[
              styles.imageButton,
              isSmallScreen ? styles.imageButtonSmall : undefined,
            ].join(" ")}
            src={home1Img}
            alt="start"
          />
          <h3 className={styles.btnTitle}>Börja laga mat</h3>
        </div>
      </div>
      <div
        className={[
          styles.containerBottom,
          isSmallScreen
            ? styles.containerBottomSmall
            : styles.containerBottomLarge,
        ].join(" ")}
      >
        {isSmallScreen && (
          <h3 className={styles.btnTitle}>
            MatGlad-helt-enkelt på TSP handbok finns på
            <br />
            <a href={HANDBOOK_URL} target="__blank" className={styles.anchor}>
              matglad.nu
            </a>
          </h3>
        )}
        <img
          src={logo1}
          className={[
            styles.logo,
            isSmallScreen ? styles.logoSmall : undefined,
          ].join(" ")}
          alt="allmänna arvsfonden"
        />
        {!isSmallScreen && (
          <h3 className={styles.btnTitle}>
            MatGlad-helt-enkelt på TSP handbok finns på
            <br />
            <a href={HANDBOOK_URL} target="__blank" className={styles.anchor}>
              matglad.nu
            </a>
          </h3>
        )}
        <img
          src={logo2}
          className={[
            styles.logo,
            isSmallScreen ? styles.logoSmall : undefined,
          ].join(" ")}
          alt="hushållningssällskapet"
        />
      </div>
    </div>
  );
};

export default withRouter(HomePage);
