import React, { useState } from "react";
import styles from "./hack_icon.module.css";
import header_logo from "../../../assets/img/header_logo.png";
import header_logo_2x from "../../../assets/img/header_logo@2x.png";
import header_logo_3x from "../../../assets/img/header_logo@3x.png";

interface props {
  onTapSuccess: () => void;
}

const HackIcon = ({ onTapSuccess }: props) => {
  const [isTapping, setIsTapping] = useState(false);
  const [amountOfTaps, setAmoutOfTaps] = useState(0);

  const resetTaps = () => {
    setIsTapping(false);
    setAmoutOfTaps(0);
  };

  const touchStart = () => {
    if (!isTapping) {
      setIsTapping(true);
      setTimeout(() => {
        resetTaps();
      }, 2000);
    }
    if (amountOfTaps === 3) {
      resetTaps();
      onTapSuccess();
    } else {
      setAmoutOfTaps((prevState) => prevState + 1);
    }
  };

  return (
    <img
      onClick={touchStart}
      className={styles.icon}
      src={header_logo}
      srcSet={`${header_logo} 1x, ${header_logo_2x} 2x, ${header_logo_3x} 3x`}
      alt="Logotyp"
    />
  );
};

export default HackIcon;
