import React from "react";
import styles from "./play_button.module.css";
import TouchWraper from "../touch_wraper";
import play1 from "../../../assets/new_ui/playfilmliten@x1.png";
import play2 from "../../../assets/new_ui/playfilmliten@x2.png";
import play3 from "../../../assets/new_ui/playfilmliten@x3.png";
import pause1 from "../../../assets/new_ui/pausfilmliten@x1.png";
import pause2 from "../../../assets/new_ui/pausfilmliten@x2.png";
import pause3 from "../../../assets/new_ui/pausfilmliten@x3.png";
import signReplay1 from "../../../assets/new_ui/sign_hand@x1.png";
import signReplay2 from "../../../assets/new_ui/sign_hand@x2.png";
import signReplay3 from "../../../assets/new_ui/sign_hand@x3.png";

export enum PlayButtonTypes {
  play = "play",
  pause = "pause",
  replay = "replay",
  sign = "sign",
}

interface props {
  onClick: () => void;
  type: PlayButtonTypes;
  id?: string;
  mini?: boolean;
  tiny?: boolean;
  className?: string;
}

const PlayButton = ({ onClick, type, id, mini, className, tiny }: props) => {
  const getImg = () => {
    switch (type) {
      case PlayButtonTypes.play:
        return (
          <img
            style={getImgSize()}
            src={play2}
            srcSet={`${play1} 1x, ${play2} 2x, ${play3} 3x`}
            alt="spela"
          />
        );
      case PlayButtonTypes.pause:
        return (
          <img
            style={getImgSize()}
            src={pause2}
            srcSet={`${pause1} 1x, ${pause2} 2x, ${pause3} 3x`}
            alt="pausa"
          />
        );
      case PlayButtonTypes.sign:
        return (
          <img
            style={getImgSize()}
            src={signReplay2}
            srcSet={`${signReplay1} 1x, ${signReplay2} 2x, ${signReplay3} 3x`}
            alt="sign"
          />
        );
      default:
        return (
          <img
            style={getImgSize()}
            src={play2}
            srcSet={`${play1} 1x, ${play2} 2x, ${play3} 3x`}
            alt="spela"
          />
        );
    }
  };
  const getImgSize = () => {
    let size = window.isTablet ? 60 : 44;
    if (mini) size = 20;
    else if (tiny) size = 20;
    return { height: size, width: size };
  };

  const getContainerSize = () => {
    let size = window.isTablet ? 80 : 60;
    if (mini) size = 30;
    else if (tiny) size = 25;
    return { height: size, width: size };
  };
  return (
    <TouchWraper onClick={onClick}>
      <div
        style={getContainerSize()}
        id={id}
        className={[
          styles.container,
          type === PlayButtonTypes.replay || type === PlayButtonTypes.sign
            ? styles.round
            : styles.standard,
          className,
        ].join(" ")}
      >
        {getImg()}
      </div>
    </TouchWraper>
  );
};

export default PlayButton;
