import fetch from "cross-fetch";
import { RecipesByCategory } from ".";

const GET_RECIPES_URL =
  process.env.REACT_APP_IS_DEV === "true"
    ? "http://127.0.0.1:5001/matglad-helt-enkelt-tsp/europe-west1/getRecipes"
    : "https://europe-west1-matglad-helt-enkelt-tsp.cloudfunctions.net/getRecipes";

export const fetchRecipesByCategory = async (): Promise<
  RecipesByCategory[] | undefined
> => {
  const isIOS = window.cordova && window.device.platform === "iOS";

  if (!isIOS) {
    const lastFetch = window.localStorage.getItem("lastFetch");
    if (lastFetch) {
      const now = new Date();
      const lastFetchDate = new Date(lastFetch);
      const diff = now.getTime() - lastFetchDate.getTime();
      const diffInHours = diff / (1000 * 3600);
      if (diffInHours < 168) {
        const cachedRecipes = window.localStorage.getItem("recipes");
        if (cachedRecipes) {
          return JSON.parse(cachedRecipes);
        }
      }
    }
  }
  try {
    const res = await fetch(GET_RECIPES_URL);
    if (!res) return;
    const recipes = await res.json();
    if (!isIOS) {
      window.localStorage.setItem("recipes", JSON.stringify(recipes));
      window.localStorage.setItem("lastFetch", new Date().toISOString());
    }
    return recipes;
  } catch (error) {
    console.error(error);
  }
};
