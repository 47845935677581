import React from "react";
import styles from "./loader.module.css";
import logo from "../../../assets/img/logo_new_big.png";

const Loader = () => {
  const getLoaders = () => {
    const amount = 10;
    const dots = [];
    const duration = amount / amount;
    for (let i = 0; i < amount; i++) {
      const curdel = i / amount;
      dots.push(
        <div
          key={i}
          style={{
            animationDelay: `${curdel}s`,
            animationDuration: `${duration}s`,
          }}
          className={styles.dot}
        />
      );
    }
    return dots;
  };
  return (
    <div className={styles.container}>
      <h1>Laddar recept</h1>
      <div className={styles.dots}>{getLoaders()}</div>
      <img className={styles.logo} src={logo} alt="Logga" />
    </div>
  );
};

export default Loader;
