import React, { useState, useEffect } from "react";
import styles from "./recipe_instruction_handler.module.css";
import { Recipe } from "../../model";
import RecipePlayer from "../recipe_player";
import TButton from "../t_button";
import { BUTTON_TYPES } from "../t_button/t_button";
import { isMobileApp } from "../../util/deviceHelpers";

interface IRecipeInstruction {
  recipe: Recipe;
  onCloseRecipe: () => void;
}

const RecipeInstructionHandler = ({
  recipe,
  onCloseRecipe,
}: IRecipeInstruction) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );
  const [showAlert, setShowAlert] = useState(false);
  const id = "recipe_instruction_id";
  const transDuration = 200;

  const onResize = () => {
    if (isMobileApp()) {
      setIsLandscape(
        window.screen.orientation.type === "landscape-primary" ||
          window.screen.orientation.type === "landscape-secondary"
      );
    } else {
      setIsLandscape(window.innerWidth > window.innerHeight);
    }
  };
  useEffect(() => {
    enter();
  }, []);

  useEffect(() => {
    const key = isMobileApp() ? "orientationchange" : "resize";
    window.addEventListener(key, onResize);
    return () => {
      window.removeEventListener(key, onResize);
    };
  }, []);

  const onNextStep = () => {
    setTimeout(() => {
      if (currentStep + 1 < recipe.steps.length) {
        setCurrentStep(currentStep + 1);
      }
      enter();
    }, transDuration);
    leave();
  };

  const onPreviousStep = () => {
    setTimeout(() => {
      if (currentStep - 1 >= 0) {
        setCurrentStep(currentStep - 1);
      }
      enter();
    }, transDuration);
    leave();
  };

  const onCancelRecipe = () => {
    if (recipe.steps[currentStep].position === recipe.steps.length - 1) {
      onCloseRecipe();
    } else {
      setShowAlert(true);
    }
  };

  const onAlertNo = () => {
    setShowAlert(false);
  };

  const onAlertYes = () => {
    setShowAlert(false);
    onCloseRecipe();
  };

  const enter = () => {
    const el = document.getElementById(id);
    if (el) {
      el.classList.toggle(styles.enter);
    }
  };

  const leave = () => {
    const el = document.getElementById(id);
    if (el) {
      el.classList.remove(styles.enter);
    }
  };

  const ExitAlert = () => (
    <div className={styles.popup}>
      <div className={styles.popup_content}>
        <p>Vill du verkligen avsluta receptet?</p>
        <TButton type={BUTTON_TYPES.button} onClick={onAlertNo}>
          Nej
        </TButton>
        <TButton type={BUTTON_TYPES.button} onClick={onAlertYes}>
          Ja
        </TButton>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div id={id} className={styles.trans}>
        <RecipePlayer
          landscape={isLandscape}
          onNextClick={onNextStep}
          onPreviousClick={onPreviousStep}
          onCancelClick={onCancelRecipe}
          name={recipe.name}
          stepNumber={recipe.steps[currentStep].position + 1}
          totalSteps={recipe.steps.length}
          step={recipe.steps[currentStep]}
        />
        {showAlert && <ExitAlert />}
      </div>
    </div>
  );
};

export default RecipeInstructionHandler;
