import React, { useRef, useState } from "react";
import styles from "./recipe_overview.module.css";
import { Recipe } from "../../model";
import TButton from "../t_button";
import { BUTTON_TYPES } from "../t_button/t_button";
import CloseButton from "../close_button";
import TBackground from "../t_background";
import RecipeTip from "../recipe_tip";
import { getHeight169 } from "../../util/size_handlers";
import sign_video_placeholder from "../../../assets/new_ui/sign_video_placeholder@3x.jpg";
const recipe2PortVideo =
  "https://firebasestorage.googleapis.com/v0/b/matglad-helt-enkelt-tsp.appspot.com/o/inforutor%2F2_portioner_video.mp4?alt=media&token=8fe01817-a5a4-4b2a-9c66-e095c892bb06";

interface Props {
  recipe?: Recipe;
  doneAnimating: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const RecipeOverview = ({
  recipe,
  onClose,
  doneAnimating,
  onContinue,
}: Props) => {
  const [showTip, setShowTip] = useState(false);
  const [show2PersTip, setShow2PersTip] = useState(false);

  const videoFillerClass =
    "recipe_video_filler_class_" + (recipe ? recipe.id : "");

  const onContinueClick = () => {
    onContinue();
  };

  const videoPlayer = useRef<HTMLVideoElement>(null);
  const videoId = `recipe-overview-video-${recipe && recipe.id}`;
  const playButtonId = `recipe-overview-play-button-${recipe && recipe.id}`;

  const playVideo = () => {
    const video = document.getElementById(videoId);
    const button = document.getElementById(playButtonId);
    if (video && button) {
      video.classList.remove(styles.videoHidden);
      button.classList.toggle(styles.hidden);
      video.classList.toggle(styles.videoShow);
    }
    setTimeout(() => {
      if (videoPlayer.current) {
        videoPlayer.current.play();
      }
    }, 200);
  };

  const onVideoEnd = () => {
    const video = document.getElementById(videoId);
    const button = document.getElementById(playButtonId);
    if (video && button) {
      video.classList.remove(styles.videoShow);
      button.classList.remove(styles.hidden);
      video.classList.toggle(styles.videoHidden);
    }
  };

  const getVideo = (recipe: Recipe) => {
    if (window.isLargeScreen) {
      const videoStyles = {
        height: getHeight169(window.innerWidth),
      };

      return (
        <div
          style={videoStyles}
          id={videoId}
          className={[
            styles.videoContainer,
            styles.video_container_tablet,
            styles.videoHidden,
          ].join(" ")}
        >
          <video
            ref={videoPlayer}
            className={[styles.video, styles.videoTablet].join(" ")}
            onEnded={onVideoEnd}
            playsInline={true}
            src={recipe.video_overview}
            preload="metadata"
            controls={false}
            poster={sign_video_placeholder}
          />
        </div>
      );
    }

    const videoStyles = {
      height: getHeight169(window.innerWidth),
    };
    return (
      <div
        id={videoId}
        className={[styles.videoContainer, styles.videoHidden].join(" ")}
      >
        <div
          className={[
            styles.video_filler,
            styles.videoHidden,
            videoFillerClass,
          ].join(" ")}
        />
        <video
          ref={videoPlayer}
          style={videoStyles}
          className={[
            styles.video,
            window.isLargeScreen && styles.videoTablet,
          ].join(" ")}
          onEnded={onVideoEnd}
          playsInline={true}
          src={recipe.video_overview}
          preload="metadata"
          controls={false}
          poster={sign_video_placeholder}
        />
        <div
          className={[
            styles.video_filler,
            styles.videoHidden,
            videoFillerClass,
          ].join(" ")}
        />
      </div>
    );
  };

  const getButtons = () => {
    return (
      <div
        className={[
          styles.buttonContainer,
          window.isLargeScreen ? styles.buttonContainerTablet : {},
        ].join(" ")}
      >
        <div className={styles.tipsContainer}>
          <TButton
            type={BUTTON_TYPES.action_2_pers}
            onClick={() => setShow2PersTip(true)}
            className={window.isLargeScreen ? undefined : styles.actionButton}
          />
          <div style={{ width: 24 }} />
          <TButton
            type={BUTTON_TYPES.action_info}
            onClick={() => setShowTip(true)}
            className={window.isLargeScreen ? undefined : styles.actionButton}
          />
        </div>
        {!window.isLargeScreen && (
          <TButton
            id={playButtonId}
            className={[styles.playButton, styles.visible].join(" ")}
            type={BUTTON_TYPES.action_play}
            onClick={playVideo}
          />
        )}
        <TButton
          type={BUTTON_TYPES.action_forward}
          onClick={onContinueClick}
          className={window.isLargeScreen ? undefined : styles.actionButton}
        />
      </div>
    );
  };

  const getImage = (doneAnim: boolean, rec: Recipe) => {
    const blurStyles = {
      visibility: doneAnimating
        ? ("visible" as "visible")
        : ("hidden" as "hidden"),
      backgroundImage: `url(${rec.image})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      flexGrow: 1,
      width: "100vw",
      display: "flex",
      position: "relative" as "relative",
    };
    const imgStyles = {
      visibility: doneAnimating
        ? ("visible" as "visible")
        : ("hidden" as "hidden"),
      backgroundImage: `url(${rec.image})`,
      backgroundSize: window.isLargeScreen ? "cover" : "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backdropFilter: "blur(20px)",
      flexGrow: 1,
      width: "100vw",
    };
    if (window.isLargeScreen) {
      return (
        <div style={blurStyles}>
          <div className={styles.blur} style={imgStyles}></div>
          {/* <img alt="" src={rec.image} style={imgStyles}/> */}
        </div>
      );
    }
    return (
      <img
        alt=""
        className={styles.img}
        src={rec.image}
        style={{ visibility: doneAnimating ? "visible" : "hidden" }}
      />
    );
  };
  // WARNING: Dubbelkolla höjden här när vi har rätt bilder
  const getDescriptionStyle = () => {
    return {
      minHeight: window.isLargeScreen ? "22%" : undefined,
    };
  };

  const onCloseTip = () => {
    setShowTip(false);
  };

  return (
    <div>
      {recipe ? (
        <TBackground className={styles.container}>
          {getImage(doneAnimating, recipe)}
          {getVideo(recipe)}
          <div className={styles.title_container}>
            <h1>{recipe.name}</h1>
          </div>
          <div
            style={getDescriptionStyle()}
            className={[
              styles.description_container,
              window.isLargeScreen && styles.description_container_tablet,
            ].join(" ")}
          >
            <p>{recipe.short_description}</p>
          </div>
          <div className={styles.close}>
            <CloseButton onClick={onClose} />
          </div>
          {getButtons()}
          {window.isLargeScreen && (
            <div className={styles.playButtonTablet}>
              <TButton
                id={playButtonId}
                className={[styles.playButton, styles.visible].join(" ")}
                type={BUTTON_TYPES.action_play}
                onClick={playVideo}
              />
            </div>
          )}
          {showTip && (
            <RecipeTip
              text={recipe.long_description}
              video={recipe.tips_video}
              onClose={onCloseTip}
              arrowStyle={{ left: window.isLargeScreen ? 110 : 90 }}
            />
          )}
          {show2PersTip && (
            <RecipeTip
              text="Recept för 2 portioner."
              video={recipe2PortVideo}
              onClose={() => setShow2PersTip(false)}
            />
          )}
        </TBackground>
      ) : null}
    </div>
  );
};

export default RecipeOverview;
