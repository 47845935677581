export const quantityString = (quantity: number) => {
		const integralPart = Math.trunc(quantity);
		const decimalPart = 4.0*(quantity - integralPart);
		const quarters = Math.trunc(decimalPart);
		let fractionString = "";
		switch (quarters) {
   		 	case 1:
	            fractionString = "¼";
	            break;
	        case 2:
	            fractionString = "½";
	            break;
	        case 3:
	            fractionString = "¾";
	            break;
	    }
		return integralPart === 0 ? fractionString : integralPart.toString() + fractionString;
	}