export enum ARIA_ROLES {
    complementary = 'complementary',
    list = 'list',
    listitem = 'listitem',
    main = 'main',
    navigation = 'navigation',
    region = 'region',
    alert = 'alert',
    application = 'application',
    article = 'article',
    banner = 'banner',
    button = 'button',
    cell = 'cell',
    checkbox = 'checkbox',
    contentinfo = 'contentinfo',
    dialog = 'dialog',
    document = 'document',
    feed = 'feed',
    figure = 'figure',
    form = 'form',
    grid = 'grid',
    gridcell = 'gridcell',
    heading = 'heading',
    img = 'img',
    listbox = 'listbox',
    row = 'row',
    rowgroup = 'rowgroup',
    search = 'search',
    switch = 'switch',
    tab = 'tab',
    table = 'table',
    tabpanel = 'tabpanel',
    textbox = 'textbox',
    timer = 'timer',
}