import React, { useContext, useEffect } from "react";
import RecipeCard from "../../components/recipe_card";
import styles from "./category_list.module.css";
import { RecipeContext } from "../../contexts/recipe";
import { NavigationContext } from "../../contexts/navigation";
import "./animation.css";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { AccessoryContext } from "../../contexts/accessory";
import EmptyCard from "../../components/empty_card";
import {
  getRecipeCategoryImage,
  getRecipeCategoryVideo,
} from "../../../helpers";
import { Recipe } from "../../model";

const CategoryList = ({ history }: RouteComponentProps) => {
  const { setAccessoryState } = useContext(AccessoryContext);
  const { recipesByCategory } = useContext(RecipeContext);
  const { setHeaderState } = useContext(NavigationContext);

  useEffect(() => {
    setAccessoryState({});
  }, [setAccessoryState]);

  useEffect(() => {
    setHeaderState({
      title: "Receptväljare",
    });
  }, [setHeaderState]);

  const handleCardClick = (categoryId: number) => {
    history.push(`/recipe-list/${categoryId}`);
  };

  const getEmptyCards = () => {
    let l = recipesByCategory.length;
    const amount = Math.floor((window.innerWidth - 50) / 300);
    const emptyCards = [];
    while (l % amount !== 0) {
      emptyCards.push(<EmptyCard key={l} />);
      l++;
    }
    return emptyCards;
  };

  const getImage = (id: number, recipes: Recipe[]) => {
    const image = getRecipeCategoryImage(id);
    if (!image) {
      return recipes[Math.floor(Math.random() * recipes.length)].image;
    }
    return image;
  };

  const getVideo = (id: number, recipes: Recipe[]) => {
    const video = getRecipeCategoryVideo(id);
    if (!video) {
      return recipes[Math.floor(Math.random() * recipes.length)].video;
    }
    return video;
  };

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {recipesByCategory.map(({ id, name, recipes }) => (
          <RecipeCard
            key={id}
            data={{
              id,
              name,
              video: getVideo(id, recipes),
              image: getImage(id, recipes),
            }}
            onCardClicked={() => handleCardClick(id)}
          />
        ))}
        {getEmptyCards()}
      </div>
    </div>
  );
};

export default withRouter(CategoryList);
