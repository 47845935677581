import React, { Component, createRef } from "react";
import PlayButton from "../../play_button";
import { PlayButtonTypes } from "../../play_button/play_button";
import styles from "./dragable_player.module.css";

import sign_video_placeholder from "../../../../assets/new_ui/sign_video_placeholder@3x.jpg";

const id = "dragable_player_container";
const overlayId = "sign_player_overlay_id";

interface IDragablePlayer {
  landscape: boolean;
  src: string;
  didEnd: () => void;
  onIsPlaying: () => void;
  isSignPlayVisible: boolean;
}
class DragablePlayer extends Component<IDragablePlayer> {
  player = createRef<HTMLVideoElement>();

  currentTime = 0;

  play = () => {
    this.props.onIsPlaying();
    this.didStart();
    setTimeout(() => {
      if (!this.player.current) return;
      this.player.current.currentTime = this.currentTime;
      this.setState({ isPlaying: true });
      this.player.current.play();
    }, 1000);
  };
  pause = () => {
    if (this.player.current) this.player.current.pause();
  };

  replay = () => {
    if (!this.player.current) return;

    this.player.current.currentTime = 0;
    this.didStart();
    setTimeout(() => {
      if (!this.player.current) return;
      this.setState({ isPlaying: true });
      this.player.current.play();
    }, 1000);

    this.props.onIsPlaying();
  };

  state = {
    right: 10,
    top: 10,
    size: 250,
    minimized: 100,
    maximized: 250,
    isPlaying: false,
  };

  componentDidMount() {
    if (this.player.current) {
      this.player.current.addEventListener("ended", this.didEnd);
    }
  }

  didEnd = () => {
    const container = document.getElementById(id);
    if (container)
      container.classList.toggle(
        this.props.landscape ? styles.minimized : styles.minimized_porttrait
      );
    const overlay = document.getElementById(overlayId);
    if (overlay) overlay.classList.remove(styles.overlay_sign_show);
    this.setState({ isPlaying: false });
    this.props.didEnd();
  };

  didStart = () => {
    const container = document.getElementById(id);
    if (container) {
      container.classList.remove(
        this.props.landscape ? styles.minimized : styles.minimized_porttrait
      );
    }
    const overlay = document.getElementById(overlayId);
    if (overlay) overlay.classList.toggle(styles.overlay_sign_show);
  };

  render() {
    const { landscape, src, isSignPlayVisible } = this.props;
    const containerStyles = [
      styles.container,
      landscape ? styles.container_absolute : styles.container_portrait,
      landscape ? styles.minimized : styles.minimized_porttrait,
    ].join(" ");

    return (
      <div style={{ width: "100%" }}>
        <div id={id} className={containerStyles}>
          <video
            ref={this.player}
            playsInline
            src={src}
            preload="metadata"
            controls={false}
            poster={sign_video_placeholder}
          />
          <div
            className={[
              styles.play_button,
              isSignPlayVisible ? styles.pb_show : undefined,
            ].join(" ")}
          >
            <PlayButton
              mini={true}
              type={PlayButtonTypes.play}
              onClick={this.play}
            />
          </div>
        </div>
        <div id={overlayId} className={styles.overlay_sign} />
      </div>
    );
  }
}

export default DragablePlayer;
