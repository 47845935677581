import React, { Dispatch, SetStateAction } from "react";

export interface HeaderState {
  title: string;
  hasBackButton?: boolean;
  showDrawer?: boolean;
}
export interface INavigationContext {
  setHeaderState: Dispatch<SetStateAction<HeaderState>>;
  header_state: HeaderState;
}
export const NavigationContext = React.createContext<INavigationContext>({
  setHeaderState: (state) => {},
  header_state: { title: "MATGLAD TSP DEFAULT", hasBackButton: true },
});
