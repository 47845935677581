import React from "react";
import styles from "./step_icons.module.css";
import { StepIngredient, Measurement } from "../../model";
import { quantityString } from "../../util/quantity_handler";

interface props {
  ingredients: StepIngredient[];
}

const StepIcons = ({ ingredients }: props) => {
  const getMeasurementIcon = (m: Measurement, quantity: number) => {
    const icons = [];
    if (m.image !== "" && m.image !== null && m.image !== undefined) {
      for (let i = 0; i < quantity; i++) {
        const style = { backgroundImage: `url(${m.image})` };
        icons.push(
          <div
            key={m.image + i}
            style={style}
            className={styles.icon_container}
          />
        );
      }
    }
    return icons;
  };

  return (
    <div>
      {ingredients.map((i) => {
        const style = {
          backgroundImage: `url(${i.recipe_ingredient.ingredient.image})`,
          backgroundColor: i.recipe_ingredient.ingredient.category.color,
        };
        const quantity = i.use_all ? i.recipe_ingredient.quantity : i.quantity;
        return (
          <div className={styles.container} key={i.id}>
            <div style={style} className={styles.icon_container} />
            {getMeasurementIcon(i.recipe_ingredient.measurement, quantity)}
            <p>
              {quantityString(quantity)} {i.recipe_ingredient.measurement.name}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default StepIcons;
