import React from 'react'
import styles from './drawer.module.css'
import { Link } from 'react-router-dom'

interface IDrawer {
    open: boolean,
    closeDrawer: () => void,
    links: {
        [key:string]: {
            title: string,
            path: string
        }
    }
}

const Drawer = ({open, closeDrawer, links} : IDrawer) => {

    return (
        <div onClick={closeDrawer} className={[styles.container, open ? styles.open : styles.closed].join(' ')}>
            <div className={[styles.drawer, open ? styles.drawerOpen : styles.drawerClosed].join(' ')}>
                <div className={styles.header}></div>
                <div className={styles.links}>
                    {Object.keys(links).map(link => 
                        <Link className={styles.link} key={link} to={links[link].path}>{links[link].title}</Link>)
                    }
                </div>
                <div className={styles.footer}></div>
            </div>
        </div>
    )
}

export default Drawer
